export enum DriverApprovalStatus {
  Pending = 'pending',
  Approved = 'approved',
}

export enum DriverActivityStatus {
  Active = 'active',
  Inactive = 'inactive',
  Suspended = 'suspended',
}
