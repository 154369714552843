import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  // children: Menu[];
}

const MENUITEMS = [
  {state: 'meter', name: 'Meter Settings', type: 'link', icon: 'av_timer'},
  {
    state: 'devices',
    name: 'Meter Devices',
    type: 'link',
    icon: 'settings_remote',
  },
  {state: 'fleets', type: 'link', name: 'Fleet', icon: 'commute'},
  {
    state: 'operators',
    type: 'link',
    name: 'Operator',
    icon: 'record_voice_over',
  },
  {state: 'vehicles', type: 'link', name: 'Vehicle', icon: 'directions_car'},
  {state: 'drivers', name: 'Driver', type: 'link', icon: 'person'},
  {state: 'passengers', name: 'Passenger', type: 'link', icon: 'group'},
  {state: 'ride-live', name: 'Live Rides', type: 'link', icon: 'local_taxi'},
  {
    state: 'ride-history',
    name: 'Ride History',
    type: 'link',
    icon: 'local_taxi',
  },
  {state: 'analytics', name: 'Analytics', type: 'link', icon: 'bar_chart'},
];

const MENUITEMSFORADMIN = [
  {state: 'users', name: 'Meter Users', type: 'link', icon: 'group'},
];

const MENUITEMSFORDRIVER = [
  {
    state: 'beamlive',
    name: 'beamLive',
    type: 'external',
    icon: 'chat',
    url: environment.pageUrl,
  },
  {state: 'ride-live', name: 'Live Rides', type: 'link', icon: 'local_taxi'},
  {state: 'analytics', name: 'Analytics', type: 'link', icon: 'bar_chart'},
  {state: 'driver-edit', name: 'Manage Driver', type: 'link', icon: 'gavel'},
  {
    state: 'vehicle-add',
    name: 'Add Vehicle',
    type: 'link',
    icon: 'directions_car',
  },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }

  getMenuitemForAdmin(): Menu[] {
    return MENUITEMSFORADMIN;
  }

  getMenuitemForDriver(): Menu[] {
    return MENUITEMSFORDRIVER;
  }
}
