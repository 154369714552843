export enum UserGender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
  Unknown = 'unknown',
}

export enum UserRole {
  Admin = 'admin',
  Client = 'client',
  Driver = 'driver',
}

export enum UserDeviceType {
  Browser = 'browser',
  Android = 'android',
  iOS = 'ios',
}
