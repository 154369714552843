import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {MenuItems, Menu} from '../../../shared/menu-items/menu-items';
import {HostBinding} from '@angular/core';
import {Input} from '@angular/core';
import {Router} from '@angular/router';
import {OnInit} from '@angular/core';
import {ApiService} from '../../../shared/api.service';
import {AuthService} from '../../../shared/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class AppSidebarComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  expanded!: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  private readonly _mobileQueryListener: () => void;
  @Input() item!: Menu;
  @Input() depth!: number;
  menuList!: Menu[];
  constructor(
    private auth: AuthService,
    private api: ApiService,
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    if (this.auth.getUserRole() === 'admin') {
      this.menuList = this.menuItems.getMenuitemForAdmin();
    } else if (this.auth.getUserRole() === 'driver') {
      this.menuList = this.menuItems.getMenuitemForDriver();
    } else {
      this.menuList = this.menuItems.getMenuitem();
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
