import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {IDriver} from '../../driver/driver.interface';
import {NotifierService} from 'angular-notifier';
import {SocketService} from '../socket.service';
import {Validator} from '../validator';
import {environment} from '../../../environments/environment';
import {IDriverType} from '../../driver/driver-type/driver-type.interface';
import {IFleet} from '../../fleet/fleet.interface';
import {IDriverSettings} from '../../driver/driver-settings/driver-settings.interface';
import {IImage} from '../shared.interface';
import {
  DriverActivityStatus,
  DriverApprovalStatus,
} from '../../driver/driver.enums';
import {UserGender} from '../../user/user.enums';

const staticAssetImagesUrl = `${environment.socketUrl}/assets/images`;

class DriverValidator extends Validator<Partial<IDriver>> {
  public validate(): boolean {
    this.clearErrors();

    this.checkRequired('firstName')
      .checkRequired('lastName')
      .checkRequired('phoneNumber')
      .checkRequired('email')
      .checkRequired('gender')
      .checkIsAccepted('isConsentAccepted');

    return this.isValid();
  }
}

@Component({
  selector: 'app-edit-driver',
  templateUrl: './edit-driver.component.html',
  styleUrls: ['./edit-driver.component.css'],
})
export class EditDriverComponent implements OnInit, OnDestroy {
  isEdit = false;
  loading = false;
  entity: IDriver = {
    _id: null,
    address: '',
    bank: {
      accountId: '',
      name: '',
      nameOnAccount: '',
      payPalId: '',
      routingId: '',
    },
    customId: '',
    dateOfBirth: null,
    generatedCode: '',
    driverRating: 0,
    driverTypeIds: [],
    email: '',
    emergencyContact: {
      name: '',
      phoneNumber: '',
      email: '',
    },
    firstName: '',
    fleetIds: [],
    gender: UserGender.Male,
    images: [],
    incidentReport: {
      comment: '',
      date: null,
    },
    insurance: {
      contact: '',
      id: '',
      name: '',
    },
    isConsentAccepted: false,
    lastName: '',
    license: {
      expiryDate: null,
      id: '',
      type: '',
    },
    middleName: '',
    nickName: '',
    phoneNumber: '',
    referringDriver: '',
    userId: null,
    approvalStatus: DriverApprovalStatus.Pending,
    activityStatus: DriverActivityStatus.Active,
    statusComment: '',
    statusNotificationTypes: [],
    suspendFromDate: null,
    suspendToDate: null,
    isManuallyAdded: true,
  };
  entityValidator = new DriverValidator(this.entity);

  driverTypesSelectDataItems: IDriverType[] = [];
  fleetsSelectDataItems: IFleet[] = [];
  driverSettings: IDriverSettings = {
    consentInformation: '',
  };

  constructor(
    private readonly router: Router,
    private readonly notifier: NotifierService,
    private readonly socketService: SocketService,
    private readonly route: ActivatedRoute
  ) {
    route.params.subscribe(params => {
      const id: string = params.id;
      if (id) {
        this.isEdit = true;
        this.loading = true;
        this.socketService.sendEvent('getOneDriver', id);
      }
    });

    if (router.url.includes('/public/')) {
      this.entity.isManuallyAdded = false;
    }

    if (router.url.includes('/driver-edit')) {
      this.isEdit = true;
      this.loading = true;
      this.socketService.sendEvent('getOneDriver');
    }
  }

  ngOnInit() {
    this.addListeners();
    this.socketService.sendEvent('getAllFleets');
    this.socketService.sendEvent('getAllDriverTypes');
    this.socketService.sendEvent('getOneDriverSettings');
  }

  addListeners() {
    this.socketService.getMessage('getOneDriver').subscribe(res => {
      this.loading = false;
      const resData = res as any;
      if (resData.meta_data.driver) {
        Object.assign(this.entity, resData.meta_data.driver);
      } else {
        this.notifier.notify('error', 'Could not found the driver');
      }
    });

    this.socketService.getMessage('editDriver').subscribe(res => {
      this.loading = false;
      const result = res as any;
      if (result.status) {
        this.notifier.notify('success', 'Driver edited successfully');
        this.router.navigate(['drivers']);
      } else {
        this.notifier.notify('error', 'Driver edit failed');
      }
    });

    this.socketService.getMessage('createDriver').subscribe(res => {
      this.loading = false;
      const resData = res as any;
      if (resData.status) {
        this.notifier.notify('success', 'Driver created successfully');
        this.router.navigate(['/drivers']);
      } else {
        this.notifier.notify(
          'error',
          'Driver creation failed: ' + resData.message
        );
      }
    });

    this.socketService.getMessage('uploadImage').subscribe(res => {
      this.loading = false;
      const resData = res as any;
      if (resData.status) {
        this.entity.images.push({
          type: resData.meta_data.type,
          fileName: resData.meta_data.fileName,
        });

        this.loading = true;
        this.socketService.sendEvent('saveDriverImages', this.entity);

        this.notifier.notify('success', 'image uploaded successfully');
      } else {
        this.loading = false;
        this.notifier.notify('error', 'failed to upload image');
      }
    });

    this.socketService.getMessage('deleteImage').subscribe(res => {
      this.loading = false;
      const resData = res as any;
      if (resData.status) {
        this.entity.images = this.entity.images.filter(
          image =>
            image.type !== resData.meta_data.type ||
            image.fileName !== resData.meta_data.fileName
        );

        this.loading = true;
        this.socketService.sendEvent('saveDriverImages', this.entity);

        this.notifier.notify('success', 'image deleted successfully');
      } else {
        this.loading = false;
        this.notifier.notify('error', 'failed to delete image');
      }
    });

    this.socketService.getMessage('saveDriverImages').subscribe(res => {
      this.loading = false;
      const result = res as any;
      if (result.status) {
        this.notifier.notify('success', 'Driver images saved successfully');
      } else if (this.isEdit) {
        this.notifier.notify('error', 'Driver images save failed');
      }
    });

    this.socketService.getMessage('getAllFleets').subscribe(res => {
      const data = res as any;
      if (data.status) {
        this.fleetsSelectDataItems = data.meta_data.fleets;
      } else {
        this.notifier.notify('error', 'Failed to get fleets');
      }
    });

    this.socketService.getMessage('getAllDriverTypes').subscribe(res => {
      const resData = res as any;
      if (resData.status) {
        this.driverTypesSelectDataItems = resData.meta_data.driverTypes;
      } else {
        this.notifier.notify('error', 'Failed to get driver types');
      }
    });

    this.socketService.getMessage('getOneDriverSettings').subscribe(res => {
      const resData = res as any;
      if (resData.meta_data.driverSettings) {
        this.driverSettings = resData.meta_data.driverSettings;
      } else {
        this.notifier.notify('error', 'Could not found the driverSettings');
      }
    });
  }

  onSubmit(): void {
    if (this.entityValidator.validate()) {
      this.loading = true;

      if (this.isEdit) {
        this.socketService.sendEvent('editDriver', this.entity);
      } else {
        this.socketService.sendEvent('createDriver', this.entity);
      }
    }
  }

  navigateBack() {
    if (this.isEdit) {
      this.router.navigate(['./../..'], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate(['./..'], {
        relativeTo: this.route,
      });
    }
  }

  ngOnDestroy() {
    this.socketService.destory('editDriver');
    this.socketService.destory('getOneDriver');
    this.socketService.destory('createDriver');
    this.socketService.destory('uploadImage');
    this.socketService.destory('deleteImage');
    this.socketService.destory('saveVehicleImages');
    this.socketService.destory('getAllFleets');
    this.socketService.destory('getAllDriverTypes');
    this.socketService.destory('getOneDriverSettings');
  }

  processImage(event: any, type: string) {
    this.loading = true;
    const socket = this.socketService;
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      socket.sendEvent('uploadImage', {
        image: reader.result,
        type: type,
      });
    };
  }

  filterImagesByType(images: IImage[], type: string) {
    return images.filter(image => image.type === type);
  }

  deleteImage(image: IImage) {
    // eslint-disable-next-line no-undef
    const r = confirm('Are you sure want to delete this image?');
    if (r) {
      this.loading = true;
      this.socketService.sendEvent('deleteImage', image);
    }
  }

  getImageSrc(image: IImage) {
    return image.fileName
      ? `${staticAssetImagesUrl}/${image.type}/${image.fileName}`
      : '';
  }
}
