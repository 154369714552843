import {Routes} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {
  AuthGuard,
  AdminGuard,
  ClientGuard,
  DriverGuard,
} from './shared/auth/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: FullComponent,

    children: [
      {
        path: '',
        canActivate: [AuthGuard, ClientGuard],
        redirectTo: '/meter',
        pathMatch: 'full',
      },
      {
        path: 'login',
        loadChildren: './shared/shared.module#SharedModule',
        pathMatch: 'full',
      },
      {
        path: 'users',
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: './user/user.module#UserModule',
      },
      {
        path: 'drivers',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './driver/driver.module#DriverModule',
      },
      {
        path: 'driver-edit',
        canActivate: [AuthGuard, DriverGuard],
        loadChildren: './driver-edit/driver-edit.module#DriverEditModule',
      },
      {
        path: 'vehicles',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './vehicle/vehicle.module#VehicleModule',
      },
      {
        path: 'vehicle-add',
        canActivate: [AuthGuard, DriverGuard],
        loadChildren: './vehicle-add/vehicle-add.module#VehicleAddModule',
      },
      {
        path: 'fleets',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './fleet/fleet.module#FleetModule',
      },
      {
        path: 'operators',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './operator/operator.module#OperatorModule',
      },
      {
        path: 'passengers',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './passenger/passenger.module#PassengerModule',
      },
      {
        path: 'ride-live',
        canActivate: [AuthGuard],
        loadChildren: './ride-live/ride-live.module#RideLiveModule',
      },
      {
        path: 'ride-history',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './ride-history/ride-history.module#RideHistoryModule',
      },
      {
        path: 'analytics',
        canActivate: [AuthGuard],
        loadChildren: './analytics/analytics.module#AnalyticsModule',
      },
      {
        path: 'meter',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './meter/meter.module#MeterModule',
      },
      {
        path: 'devices',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './device/device.module#DeviceModule',
      },
    ],
  },
  {
    path: 'public',
    loadChildren: './public/public.module#PublicModule',
  },
];
