import {Injectable} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {SocketIO} from './socket.custom';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(
    private readonly socket: SocketIO,
    private readonly auth: AuthService
  ) {
    this.errorHandler();
  }

  /**
   * Reconnects with current auth token
   */
  connect() {
    if (!this.socket.ioSocket.disconnected) {
      this.socket.disconnect();
    }
    this.socket.ioSocket.io.opts.query = {accessToken: this.auth.getToken()};
    this.socket.connect();
  }

  sendEvent(
    event: string,
    data?: Record<string, any> | string,
    callback?: Function
  ) {
    if (callback) {
      return this.socket.emit(event, data, callback);
    }
    return this.socket.emit(event, data);
  }

  getMessage(event: any) {
    return this.socket.fromEvent(event);
  }

  destory(event: any) {
    this.socket.removeAllListeners(event);
  }

  disconnect() {
    this.socket.disconnect();
  }

  errorHandler() {
    this.socket.on('exception', (err: any) => {
      if (err.code === 401) {
        this.disconnect();
        this.auth.logout();
      } else {
        console.error(err);
      }
    });
  }
}
