// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: 'http://localhost:4000/api/v1',
  apiKey: 'AIzaSyBdiNxE31mGELQwmau05oOcld_1Kk2ED0s',
  gpsApiUrl: 'https://hpd-gps.beam.live/api/v1',
  socketUrl: 'http://localhost:4000',
  staticAssetUrl: 'http://localhost:4000',
  stripeKey: 'pk_test_Et647M9Zop5eCXjCXiuFawOV',

  siteUrl: 'http://localhost:4200/',
  pageUrl: 'http://localhost:4200/',
};
